import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'; // Para capturar parámetros de la URL
import logo from '../../images/fuku.png';
import './css/success.css'; // Archivo CSS para estilos

const Success = () => {
  // Capturar los parámetros de la URL enviados por Mercado Pago
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extraer los datos de los parámetros de la URL
  const paymentId = queryParams.get('payment_id') || "N/A";
  const status = queryParams.get('status') || "N/A";
  const preferenceId = queryParams.get('preference_id') || "N/A";
  const amount = queryParams.get('amount') || "N/A"; // Monto de la transacción

  // Deep Link para redirigir a la app móvil
  const deepLink = `myapp://success?payment_id=${paymentId}&status=${status}&preference_id=${preferenceId}&amount=${amount}`;

  // Estado para manejar el botón
  const [buttonText, setButtonText] = useState('Volver a Fukutsu');
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDeepLink = () => {
    // Cambiar el estado del botón
    setIsDisabled(true);
    setButtonText('Ya puede cerrar esta página');

    // Intentar redirigir al Deep Link
    window.location.href = deepLink;

    // Intentar cerrar la ventana después de 1 segundo
    setTimeout(() => {
      window.close();
    }, 1000);
  };

  return (
    <div className="success-container">
      <div className="success-logo">
        <img src={logo} alt="Fukutsu Logo" />
      </div>
      <h1 className="success-title">¡Pago Exitoso!</h1>
      <p className="success-message">Gracias por tu compra.</p>
      <div className="success-details">
        <ul>
          <li>
            <strong>Payment ID:</strong> {paymentId}
          </li>
          <li>
            <strong>Status:</strong> {status}
          </li>
          <li>
            <strong>Preference ID:</strong> {preferenceId}
          </li>
          <li>
            <strong>Monto:</strong> ${amount}
          </li>
        </ul>
      </div>
      <button
        className="deep-link-button"
        onClick={handleDeepLink}
        disabled={isDisabled} // Desactivar el botón cuando esté bloqueado
        style={{
          backgroundColor: isDisabled ? '#cccccc' : '#28a745', // Verde para el estado normal
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          fontSize: '16px',
        }}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default Success;
