import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'; // Cambiar HashRouter por BrowserRouter
import Failure from './pages/failure/failure';
import Pending from './pages/pending/pending';
import Success from './pages/success/success';
import Payment from './Payment';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/payment/:sandboxUrl" element={<Payment />} />
        <Route path="/success" element={<Success />} />
        <Route path="/failure" element={<Failure />} />
        <Route path="/pending" element={<Pending />} />
      </Routes>
    </Router>
  );
}

export default App;
